import { Link } from 'gatsby'
import React from "react"
// import { useReduceMotion } from "react-reduce-motion";
import { useScrollAnimation } from "../components/useScrollAnimation";
import { motion, useTransform } from "framer-motion";
import Layout from '../components/Layout'
import Seo from '../components/seo'
import BannerMp4 from '../videos/what.mp4'
import BannerWebm from '../videos/what.webm'
import BannerOgv from '../videos/what.ogv'
import BannerCover from '../videos/video_cover_what.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/scrollbar"
import SwiperCore, { Scrollbar } from 'swiper';
import { StaticImage } from "gatsby-plugin-image";
import TechLogos from '../components/TechLogos'


SwiperCore.use([Scrollbar]);


export default function Work() {

    // const prefersReducedMotion = useReduceMotion();
    const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

    const [bannerRef, bannerScroll] = useScrollAnimation();
    const bannerScale = useTransform(bannerScroll, [0, 0.5, 1], prefersReducedMotion ? [1, 1, 1] : [1, 1, 1.2]);


    return (
        <Layout>
            
            <Seo
                title='Software &amp; app development | Webfox - Work We’ve Done'
                description='We use intelligent technology to bring ideas to life online for government organisations and businesses across a range of industries. See our work.'
            />


            {/* section1 */}
            <div ref={bannerRef}>
                <section className="layer intro intro-default dark">
                    <motion.video animate={{ scale: bannerScale.get() }} id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
                        <source src={BannerWebm} type='video/webm' />
                        <source src={BannerMp4} type='video/mp4' />
                        <source src={BannerOgv} type='video/ogg' />
                    </motion.video>

                    <div className="inner">
                        <div className="copy">
                            <h1>Work We've Done</h1>
                        </div>
                    </div>
                </section>
            </div>

            <section className="layer featured-projects double-text transparent">
                <div className="inner">
                    <div className="grid col-2 align-top">
                        <div className="col">
                            <h2>We’ve helped Kiwi businesses bring ideas to life through digital tools</h2>
                        </div>
                        <div className="col">
                            <p>We use intelligent technology to bring ideas to life online for government organisations and businesses across a range of industries. Take a look at some of our latest work below.</p>
                        </div>
                    </div>

                    <Swiper
                        className='tiles'
                        spaceBetween={26}
                        slidesPerView={4}
                        freeMode={false}
                        loop={false}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        scrollbar={{
                            draggable: true,
                            el: '.swiper-scrollbar',
                        }}
                        breakpoints={{
                            1: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            450: {
                                slidesPerView: 2,
                                spaceBetween: 16,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 26,
                            }
                        }}
                    >
                        <SwiperSlide className="tile">
                            <Link to="/work-weve-done/funeral-stream" className="background-zoom-image">
                                <StaticImage src="../images/projects/funeral_stream/funeral-stream-tile.jpg" alt="Couple watching Funeral Stream" />
                            </Link>
                            <div className="copy">
                                <h5>Funeral Stream</h5>
                                <p>SaaS Platform Development</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="tile">
                            <Link to="/work-weve-done/case-study" className="background-zoom-image">
                                <StaticImage src="../images/project1.jpg" alt="TODO" />
                            </Link>
                            <div className="copy">
                                <h5>Clearva</h5>
                                <p>Software Platform Development</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="tile">
                            <Link to="/work-weve-done/redsteel" className="background-zoom-image">
                                <StaticImage src="../images/projects/redsteel/redsteel-tile.jpg" alt="eSteel Software for Steel Fabrication" />
                            </Link>
                            <div className="copy">
                                <h5>eSteel</h5>
                                <p>Cloud Software Development</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="tile">
                            <Link to="/work-weve-done/pioneer" className="background-zoom-image">
                                <StaticImage src="../images/projects/pioneer/pioneer-tile.jpg" alt="Pioneer Framing Website" />
                            </Link>
                            <div className="copy">
                                <h5>Pioneer Framing</h5>
                                <p>Bespoke Website Development</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="tile">
                            <Link to="/work-weve-done/case-study" className="background-zoom-image">
                                <StaticImage src="../images/project1.jpg" alt="TODO" />
                            </Link>
                            <div className="copy">
                                <h5>Business Name</h5>
                                <p>Job Type</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="tile">
                            <Link to="/work-weve-done/case-study" className="background-zoom-image">
                                <StaticImage src="../images/project1.jpg" alt="TODO" />
                            </Link>
                            <div className="copy">
                                <h5>Business Name</h5>
                                <p>Job Type</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="tile">
                            <Link to="/work-weve-done/case-study" className="background-zoom-image">
                                <StaticImage src="../images/project1.jpg" alt="TODO" />
                            </Link>
                            <div className="copy">
                                <h5>Business Name</h5>
                                <p>Job Type</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="tile">
                            <Link to="/work-weve-done/case-study" className="background-zoom-image">
                                <StaticImage src="../images/project1.jpg" alt="TODO" />
                            </Link>
                            <div className="copy">
                                <h5>Business Name</h5>
                                <p>Job Type</p>
                            </div>
                        </SwiperSlide>
                        <div className="swiper-scrollbar"></div>
                    </Swiper>

                </div>
            </section>



            <TechLogos />

        </Layout>

    )
}
